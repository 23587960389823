@import "fonts";
@import '~bootstrap/scss/functions';
@import "variables_welcome_b5";
@import "~bootstrap/scss/bootstrap";
@import 'ql';

body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: .3rem;

	@include media-breakpoint-up(sm) {
		padding: 4rem 2rem;
	}
}

#hero {
	margin: 70px auto;
}


.blog-post {
	font-size: 1.3rem;
	color: rgb(68, 68, 68);

	h1, h2, h3, h4, h5, h6 {
		margin-bottom: 30px;
	}
	
	p, ul, ol {
		font-family: 'Frank Ruhl Libre', serif;
		margin-bottom: 30px;
	}
}

.btn-white {
	background-color: #fff;
}
.btn-white:hover {
	background-color: rgba(#fff, .8);
}
.grecaptcha-badge{
	visibility: collapse !important;  
}

.feature-text {
	line-height: 28px;
}